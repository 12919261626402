export const environment = {
    production: true,
    envName: "fromTFS",
    clientID: "650d64c6-a349-489f-b3d2-3b0a926bfa0f",
    urlCloudApi: "https://testaccount.ansys.com/",
    authority: "https://login.microsoftonline.com/tfp/a365dev.onmicrosoft.com/B2C_1_AccountAdmin_sign_in_test",
    adminGroupId: "375184be-7f1e-4dc2-88a7-5bd245c3afd6",
    oidc: "https://testaccountregistration.ansys.com",
    oidcClientId: "portal",
    appInsights: { instrumentationKey: "2d731c46-4121-4db6-a1b3-2c14bdee85dc" },
    enableAnsysOnline: false,
};
